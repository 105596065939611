.backdrop-header-container {
  z-index: 100;
}

.header-container {
  display: flex;
  flex-direction: row;
  position: fixed;
  top: 24px;
  right: 90px;
  justify-content: flex-end;
  z-index: 100;
}

.logout-btn {
  border: 2px solid #ff7f11;
  border-radius: 30px;
  padding: 12px 30px;
  text-align: center;
  color: black;
  font-size: 18px;
  font-weight: 600;
  box-shadow: 0 3px 6px rgba(255, 255, 255, 0.5);
  cursor: pointer;
  transition: background-color 0.3s;
  min-width: 240px;
  outline: none;
  height: fit-content;
  position: relative;
  background-color: #ff7f11;
  z-index: 2;
  max-width: calc(100vw - 108px);
  box-sizing: border-box;
  overflow: hidden;
}

.logout-btn:hover {
  color: white;
  background-color: black;
}

.logout-btn .logout {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.logout-btn .email,
.logout-btn:hover .logout {
  opacity: 1;
}

.logout-btn .logout,
.logout-btn:hover .email {
  opacity: 0;
}

.balance-txt {
  position: absolute;
  top: 60px;
  right: 156px;
  background-color: black;
  color: white;
  text-align: center;
  border: 3px solid #ff7f11;
  border-radius: 12px;
  padding: 6px 18px;
  max-width: 180px;
}

.menu-container {
  margin-left: 18px;
}

.menu-container button.MuiButtonBase-root.MuiFab-root.MuiFab-sizeLarge {
  background-color: #ff7f11 !important;
}

.language-btn {
  border-radius: 50%;
  background-color: #ff7f11;
  min-width: 54px;
  min-height: 54px;
  max-width: 54px;
  max-height: 54px;
  border: 3px solid white;
  transition: border-color 0.3s;
  outline: none;
  cursor: pointer;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.language-btn img {
  object-fit: cover;
  width: 54px;
  height: 54px;
}

.language-btn:hover {
  border-color: #ff7f11;
}

@media (max-width: 780px) {
  .header-container {
    top: 18px;
    right: 12px;
  }

  .menu-container button.MuiButtonBase-root {
    max-width: 42px;
    max-height: 42px;
  }

  .language-btn {
    min-width: 36px;
    min-height: 36px;
    max-width: 36px;
    max-height: 36px;
    border: 2px solid white;
    margin-top: 3px;
  }

  .language-btn img {
    object-fit: cover;
    width: 36px;
    height: 36px;
  }
}
