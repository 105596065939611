.container {
  width: 100%;
}

.subcontainer {
  text-align: center;
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 90px;
  max-width: 1800px;
  margin: auto;
}

.column {
  flex: 1;
  text-align: left;
}

.title {
  color: white;
  font-weight: 500;
  text-align: left;
  font-size: 48px;
  margin: 0;
}

.description {
  margin: 24px 0;
  color: white;
  font-weight: 300;
  text-align: left;
  font-size: 24px;
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 60px;
}

.row a {
  padding: 18px 42px;
  margin: 30px 0 0 0;
  outline: none;
  cursor: pointer;
  text-align: center;
  color: white;
  border: 2px solid black;
  border-radius: 30px;
  font-size: 18px;
  font-weight: 600;
  text-decoration: none;
}

.row a:hover {
  border-color: white;
}

.btn-whitepaper {
  background-image: linear-gradient(to right, #ff7f11, black);
}

.btn-video {
  background-image: linear-gradient(to right, #6758f3, black);
}

.logo {
  height: 90px;
  pointer-events: none;
  margin-top: 60px;
}

@media (prefers-reduced-motion: no-preference) {
  .logo {
    animation: App-logo-spin infinite 6s linear;
  }
}

@keyframes App-logo-spin {
  0% {
    transform: scale(1);
    opacity: 0.5;
  }
  50% {
    transform: scale(1.1);
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 0.5;
  }
}

@keyframes moveUpCard {
  0% {
    top: -2000px;
    opacity: 0;
  }
  100% {
    top: 90px;
    opacity: 1;
  }
}

.card {
  text-align: center;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 30px;
  box-shadow: 0 0 30px rgba(255, 255, 255, 0.3);
  overflow: hidden;
  transition: all 0.3s;
  position: relative;
}

.subtitle {
  color: white;
  font-weight: 700;
  font-size: 24px;
  margin: 0;
  background-color: rgb(255, 127, 17, 0.75);
  padding: 30px;
}

.info-card {
  padding: 30px;
}

.row-quantity {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.path-bar {
  height: 9px;
  width: 100%;
  border-radius: 18px;
  background-color: rgba(255, 255, 255, 0.75);
  overflow: hidden;
}

.progress-bar {
  background-color: #6758f3;
  width: 0%;
  height: 100%;
  border-radius: 18px;
  transition: all 0.5s;
}

.quantity {
  color: white;
  font-size: 18px;
  margin: 12px 0 18px 0;
  font-weight: 500;
  text-align: left;
}

.quantity.right {
  text-align: right;
}

.btn-buy {
  padding: 18px 42px;
  margin: 30px 0 0 0;
  outline: none;
  cursor: pointer;
  text-align: center;
  color: white;
  border: none;
  border-radius: 30px;
  font-size: 18px;
  font-weight: 600;
  background-image: linear-gradient(to right, #6758f3, black);
  width: 100%;
}

.label-email {
  color: white;
  font-size: 18px;
  margin: 48px 0 6px 0;
}

.label-email b {
  font-size: 42px;
  color: #ff7f11;
}

.label-email span {
  color: #ff7f11;
  cursor: pointer;
}

.backdrop {
  display: none !important;
  position: fixed;
  z-index: 3;
}

@media (min-width: 900px) {
  .backdrop {
    display: flex !important;
  }

  .card {
    animation: moveUpCard 0.5s linear;
  }

  .float-modal {
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    top: 90px;
    z-index: 3;
    background-color: black;
    max-height: calc(90vh - 90px);
    overflow: hidden;
    width: 90%;
    max-width: 840px;
  }

  .float-modal .info-card {
    overflow: auto;
    max-height: calc(75vh - 90px);
  }
}

@media (max-width: 900px) {
  .subcontainer {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 60px;
  }

  .column {
    flex: unset;
    width: 100%;
  }

  .logo {
    height: 48px;
    margin-top: 30px;
    width: 100%;
    object-fit: contain;
  }

  .title {
    font-size: 36px;
  }

  .row {
    gap: 6px;
    flex-wrap: wrap;
  }

  .row a {
    margin: 12px 0 0 0;
    width: 100%;
    box-sizing: border-box;
  }

  .card {
    margin-bottom: 60px;
  }

  .subtitle {
    font-size: 18px;
    padding: 24px;
  }

  .quantity {
    font-size: 14px;
  }

  .label-email b {
    font-size: 30px;
  }
}
