.features-container {
  width: 100%;
  text-align: center;
  /* padding-top: 120px; */
  padding-top: 30px;
}

.features-title {
  text-align: center;
  color: white;
  font-weight: 600;
  font-size: 48px;
  background-color: #ff7f11;
  width: fit-content;
  display: inline;
  padding: 12px 60px;
  border-radius: 60px;
  border: 3px solid white;
  margin: 30px 0 0 0;
}

.features-description {
  color: white;
  font-size: 24px;
  width: 90%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 60px 0 0 0;
  text-align: justify;
}

.features-grid {
  width: 90%;
  margin: 90px auto 60px auto;
  max-width: 1080px;
}

.item-grid {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: black;
  box-shadow: 0 0 30px rgba(255, 255, 255, 0.1);
  border-radius: 24px;
  padding: 30px;
  margin: 60px 0 0 0;
  border: 3px solid rgba(255, 255, 255, 0.5);
  transition: all 0.3s;
  position: relative;
}

.item-grid:hover {
  background-color: white;
  border-color: #ff7f11;
}

.item-grid:hover h1,
.item-grid:hover p {
  color: black;
}

.item-grid a,
.item-grid span {
  color: #ff7f11;
  text-decoration: none;
}

.image-item-grid {
  border-radius: 50%;
  overflow: hidden;
  border: 3px solid white;
  object-fit: contain;
  padding: 12px;
  min-width: 90px;
  min-height: 90px;
  max-width: 90px;
  max-height: 90px;
  margin-right: 30px;
}

.title-item-grid {
  color: #ff7f11;
  font-weight: 600;
  font-size: 36px;
  text-align: left;
  margin: 0;
}

.description-item-grid {
  color: white;
  font-size: 24px;
  text-align: left;
  margin: 12px 0;
}

.features-comming-soon-btn {
  background-color: #6758f3;
  color: white;
  text-align: center;
  padding: 18px 60px;
  border-radius: 30px;
  margin: 0 auto 120px auto;
  width: fit-content;
  font-size: 30px;
  font-weight: 600;
  box-sizing: border-box;
}

.comming-soon-item-grid {
  position: absolute;
  color: white;
  background-color: #ff7f11;
  border-radius: 30px;
  top: 30px;
  right: 30px;
  padding: 6px 12px;
}

.new-item-grid {
  position: absolute;
  color: black;
  background-color: #61d095;
  border-radius: 30px;
  top: 30px;
  right: 30px;
  padding: 6px 24px;
}

.features-exchange-currency {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 42px auto 0 auto;
  background-color: white;
  width: 90%;
  max-width: 720px;
  border-radius: 60px;
  border: 6px solid #ff7f11;
  padding: 24px 12px;
}

.flag-exchange-currency {
  width: 90px;
  height: 90px;
  border-radius: 50%;
  overflow: hidden;
  background-color: black;
  border: 3px solid black;
  margin: 0 12px;
}

.flag-exchange-currency img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.label-exchange-currency {
  font-size: 42px;
  font-weight: bold;
  margin: 0;
}

.label-exchange-currency span {
  font-size: 24px;
}

.equal-exchange-currency {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  font-size: 42px;
  font-weight: bold;
  border: 3px solid black;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 24px;
}

@media (max-width: 900px) {
  .features-container {
    padding-top: 30px;
  }

  .features-title {
    font-size: 30px;
    padding: 12px 24px;
    border-radius: 60px;
    border: 3px solid white;
    margin: 0;
  }

  .features-description {
    font-size: 18px;
  }

  .item-grid {
    flex-direction: column;
    align-items: flex-start;
    padding: 30px 12px;
  }

  .image-item-grid {
    margin-right: 0px;
  }

  .title-item-grid {
    text-align: left;
    font-size: 24px;
    margin-top: 30px;
  }

  .description-item-grid {
    font-size: 18px;
    text-align: left;
    margin: 12px 0;
  }

  .features-comming-soon-btn {
    padding: 18px;
    font-size: 24px;
    max-width: 90%;
  }

  .features-exchange-currency {
    border: 3px solid #ff7f11;
    padding: 24px 6px;
  }

  .flag-exchange-currency {
    width: 42px;
    height: 42px;
    min-width: 42px;
    min-height: 42px;
    margin: 0 12px;
  }

  .label-exchange-currency {
    font-size: 30px;
    font-weight: bold;
    line-height: 18px;
  }

  .label-exchange-currency span {
    font-size: 12px;
    font-weight: 500;
  }

  .equal-exchange-currency {
    width: 24px;
    height: 24px;
    font-size: 24px;
    margin: 0 6px;
    border: 1px solid black;
  }
}
